import { render, staticRenderFns } from "./Pien.vue?vue&type=template&id=17383660&scoped=true&"
import script from "./Pien.vue?vue&type=script&lang=js&"
export * from "./Pien.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17383660",
  null
  
)

export default component.exports